<template>
  <b-modal
    class="bmodal"
    size="sm"
    id="genericVerifyModal"
    ref="modalGenericVerify"
    :title="title"
    :no-close-on-backdrop="noCloseOnBackdrop"
    @ok="handleClickOk"
    @cancel="handleClickCancel"
    :cancel-title='i18n["tt-generic-verify"].tcCancel'
    :ok-title='i18n["tt-generic-verify"].tcContinue'
  >
    <div>
      {{ verbiage_a }}
      <p>
        <br />
        {{ verbiage_b }}
      </p>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
export default {
  name: "GenericVerify",
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'tt-generic-verify': {
            tcCancel: 'Cancel',
            tcContinue: 'Continue',
          }
        }
      }
    },
    num: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    verbiage_a: {
      type: String,
      default: ""
    },
    verbiage_b: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      noCloseOnBackdrop: true
    };
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit("modalClose");
      this.$refs.modalGenericVerify.hide();
    },
    handleClickCancel() {
      this.$emit("modalClose");
      this.allowEdit = false;
      this.closeModal();
    },
    handleClickOk() {
      this.$emit("modalFollowUp", this.num);
      this.closeModal();
    }
  }
};
</script>

<style lang="scss">
.mb10 {
  margin-bottom: 10px;
}
.mtb30 {
  margin: 30px 0;
}
.ml30 {
  margin-bottom: 30px;
}
.lh54 {
  line-height: 54px;
}
.nonew {
  padding: 30px;
}
@import '@/styles/settings.scss';
.membership {
  .page-body {
    margin-top: 60px;
    h2 {
      margin-bottom: 36px;
    }
  }
  .section-1,
  .section-2 {
    margin-bottom: 52px;
    > .row {
      margin-bottom: 40px;
    }
    .t-container {
      @include breakpoint(sm) {
        margin-bottom: 1rem;
      }
    }
    .col {
      @include breakpoint(sm) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
      &:first-of-type {
        padding-right: 23px;
      }
      &:last-of-type {
        padding-left: 23px;
        .number-card:last-of-type {
          margin-bottom: 0;
        }
      }
      table {
        width: 100% !important;
      }
    }
  }
  .section-3 {
    margin-bottom: 60px;
    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }
      &:nth-child(2n) {
        padding-left: 23px;
      }
    }
    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
