<template>
  <div>
    <div v-show="displayRmtKey === rmtKey && displayRmdKey === rmdKey">
        <b-container class="twelve" v-for="(historyDetail, ord) in historyDetails" :key="`o${ord}`">
          <b-container class="twelve" v-for="(details, ordx) in historyDetail.remittanceDetail" :key="`o${ordx}`">
            <b-row v-if="details.remittanceFunds.ch_receipt > 0 || details.remittanceFunds.ch_presentation_num > 0">
              <b-col sm="1"></b-col>
              <b-col sm="8" class="font-weight-bold">{{ i18n['HistoryDetails'].tcReceipts }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ch_receipt > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6" >{{ i18n['HistoryDetails'].tcChurchReceipts }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.ch_receipt | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ch_presentation_num > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcNumberOfChurchesWithPresentations }}</b-col>
              <b-col sm="2"></b-col>
              <b-col sm="1" class="text-right">{{ details.remittanceFunds.ch_presentation_num }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.gc_receipt > 0 || details.remittanceFunds.gc_donor_num > 0 || details.remittanceFunds.gc_presentation_gid_num > 0
              || details.remittanceFunds.gc_presentation_aux_num > 0 || details.remittanceFunds.bj_receipt > 0 || details.remittanceFunds.bj_church_num > 0">
              <b-col sm="1"></b-col>
              <b-col sm="8" class="font-weight-bold">{{ i18n['HistoryDetails'].tcGideonCard }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.gc_receipt > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcGideonCardReceipts }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.gc_receipt | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.gc_donor_num > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcNumberOfGideonCardDonors }}</b-col>
              <b-col sm="2"></b-col>
              <b-col sm="1" class="text-right">{{ details.remittanceFunds.gc_donor_num }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.gc_presentation_gid_num > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcGideonCardPresentationsGideon }}</b-col>
              <b-col sm="2"></b-col>
              <b-col sm="1" class="text-right">{{ details.remittanceFunds.gc_presentation_gid_num }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.gc_presentation_aux_num > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcGideonCardPresentationsAuxiliary }}</b-col>
              <b-col sm="2"></b-col>
              <b-col sm="1" class="text-right">{{ details.remittanceFunds.gc_presentation_aux_num }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.bj_receipt > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcBirthdayForJesusCardReceipts }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.bj_receipt | toCurrency }}</b-col>
              <b-col sm="1"></b-col>

            </b-row>
            <b-row v-if="details.remittanceFunds.bj_church_num > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcBirthdayForJesusNumberOfChurches }}</b-col>
              <b-col sm="2"></b-col>
              <b-col sm="1" class="text-right">{{ details.remittanceFunds.bj_church_num }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.aux_script_fund > 0">
              <b-col sm="1"></b-col>
              <b-col sm="8" class="font-weight-bold">{{ i18n['HistoryDetails'].tcAuxiliaryScriptureFund }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.aux_script_fund > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcAuxiliaryScriptureFundReceipts }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.aux_script_fund | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ff_annual_pastor_event > 0 || details.remittanceFunds.ff_area_mtg > 0 || details.remittanceFunds.ff_sta_conv_cdn > 0
              || details.remittanceFunds.ff_sta_conv_aux > 0 || details.remittanceFunds.ff_int_conv_gid > 0 || details.remittanceFunds.ff_int_conv_aux > 0
              || details.remittanceFunds.ff_aux_evt > 0 || details.remittanceFunds.ff_cmp_ff > 0">
              <b-col sm="1"></b-col>
              <b-col sm="8" class="font-weight-bold">{{ i18n['HistoryDetails'].tcFaithFund }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ff_annual_pastor_event > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcAnnualPastorsEvent }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.ff_annual_pastor_event | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ff_area_mtg > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcAreaFaithFundMeeting }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.ff_area_mtg | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ff_sta_conv_cdn > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcStateConventionCountdown100 }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.ff_sta_conv_cdn | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ff_sta_conv_aux > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcStateConventionAuxHeartPgm }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.ff_sta_conv_aux | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ff_int_conv_gid > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcInternationalConventionGideons }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.ff_int_conv_gid | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ff_int_conv_aux > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcInternationalConventionAuxHeartPgm }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.ff_int_conv_aux | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ff_aux_evt > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcAuxiliaryEvent }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.ff_aux_evt | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.ff_cmp_ff > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcCampFaithFund }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.ff_cmp_ff | toCurrency }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.oth_cmp_scr_fund + details.remittanceFunds.oth_usa_out_fund > 0">
              <b-col sm="1"></b-col>
              <b-col sm="8" class="font-weight-bold">{{ i18n['HistoryDetails'].tcOtherDesignatedFunds }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.oth_cmp_scr_fund > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcCampScriptureFunds }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.oth_cmp_scr_fund | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.oth_usa_out_fund > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcUSAOutreachFunds }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.oth_usa_out_fund | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.bf_cmp_fund > 0 || details.remittanceFunds.bf_sta_fund > 0">
              <b-col sm="1"></b-col>
              <b-col sm="8" class="font-weight-bold">{{ i18n['HistoryDetails'].tcBarnabasFund }}</b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.bf_cmp_fund > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcBFCampOfferingFund }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.bf_cmp_fund | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
            <b-row v-if="details.remittanceFunds.bf_sta_fund > 0">
              <b-col sm="2"></b-col>
              <b-col sm="6">{{ i18n['HistoryDetails'].tcBFStateConvention }}</b-col>
              <b-col sm="2" class="text-right">{{ details.remittanceFunds.bf_sta_fund | toCurrency }}</b-col>
              <b-col sm="1"></b-col>
            </b-row>
          </b-container>
        </b-container>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "HistoryDetails",
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'HistoryDetails': {
            tcAnnualPastorsEvent: '1. Annual Pastors Event',
            tcAreaFaithFundMeeting: '2. Area Faith Fund Meeting',
            tcAuxiliaryEvent: '5. Auxiliary Events',
            tcAuxiliaryScriptureFund: 'Auxiliary Scripture Fund',
            tcAuxiliaryScriptureFundReceipts: 'Auxiliary Scripture Fund Receipts',
            tcBarnabasFund: 'Barnabas Fund',
            tcBFStateConvention: 'Barnabas Fund - State Convention',
            tcBFCampOfferingFund: 'Barnabas Fund - Camp Offering',
            tcBirthdayForJesusCardReceipts: 'Birthday for Jesus Receipts',
            tcBirthdayForJesusNumberOfChurches: 'Birthday for Jesus - # of Churches',
            tcCampFaithFund: 'Camp Faith Fund Receipts',
            tcCampScriptureFunds: 'Camp Scripture Fund Receipts',
            tcChurchReceipts: 'Church Receipts',
            tcFaithFund: 'Faith Fund',
            tcGideonCard: 'GideonCard',
            tcGideonCardPresentationsAuxiliary: 'GideonCard Presentations - Auxiliary',
            tcGideonCardPresentationsGideon: 'GideonCard Presentations - Gideon',
            tcGideonCardReceipts: 'GideonCard Receipts',
            tcInternationalConventionAuxHeartPgm: '4b. Intl Convention - Auxiliary Heart Program',
            tcInternationalConventionGideons: '4a. Intl Convention - Summer Faith Fund Rallies',
            tcNumberOfChurchesWithPresentations: '# of Churches with Presentations',
            tcNumberOfGideonCardDonors: '# of GideonCard Donors',
            tcOtherDesignatedFunds: 'Other Designated Funds',
            tcReceipts: 'Church',
            tcStateConventionAuxHeartPgm: '3b. State Convention - Auxiliary Heart Program',
            tcStateConventionCountdown100: '3a. State Convention - Countdown 100',
            tcUSAOutreachFunds: 'USA Outreach & Distribution Fund Receipts'
          }
        }
      }
    },
    rmtKey: {
      type: String,
      default: ""
    },
    displayRmtKey: {
      type: String,
      default: ""
    },
    rmdKey: {
      type: String,
      default: ""
    },
    displayRmdKey: {
      type: String,
      default: ""
    },
    historyDetails: {
      type: Array,
      default: []
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.twelve {
  font-size: 16px;
  margin-bottom: 20px;
  padding: 10px 0;
  background-color: #f5f5f5;
  color: black;
  @include breakpoint(sm) {
    padding: 3px;
  }
}
</style>